import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const StyledDesignPageWrapper = styled.div`
  h3 {
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 2.375rem;
    letter-spacing: 0.02em;
    margin: 1.5rem 0;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
      font-size: 1.5rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
      font-size: 1.25rem;
    }
  }
`;

export const StyledCatchUpSectionContainer = styled.div`
  & .catchup-section__second {
    width: auto;
    margin-bottom: 3.375rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
      width: 100vw;
      margin-left: -6.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
      margin-bottom: 2.5rem;
      margin-left: -1.5rem;
    }
  }
`;
